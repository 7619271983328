import React, { useEffect, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import axios from 'axios';
import {
  RadioButtonGroup,
  Input,
  IconName,
  Icon,
  Slider,
  InlineField,
  //s Form,
  Button,
  //Alert,
  //commonOptionsBuilder,
} from '@grafana/ui';
import swal from 'sweetalert';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  //const [datasourceUID, setDatasourceUID] = useState<string>('');
  const [selected, setSelected] = useState('phone');
  const [inputIcon, setInputIcon] = useState<IconName>('book');
  const [inputPlaceholdText, setinputPlaceholdText] = useState<string>('Enter phone number');
  const [contactInfo, setContactInfo] = useState<string>('');
  const [alertName, setAlertName] = useState<string>('empty');
  const [thresholdVoltage, setThresholdVoltage] = useState<number>(7);
  const [backendPluginId, setBackendPluginId] = useState<string>('');
  // const [loggedIn, setLoggedIn] = useState<boolean>(false);
  //var alertTemplate = require('./alertTemplate.json');

  useEffect(() => {
    console.log('here2');
    // axios.get('/api/admin/settings').then((res) => {
    //   console.log('initial response', res);
    //   if (res.status === 200) {
    //     setLoggedIn(true);
    //   } else {
    //     setLoggedIn(false);
    //   }
    // });

    axios.get('/api/datasources').then((res) => {
      console.log(res.data);
      for (var i = 0; i < res.data.length; i++) {
        if (res.data[i].name === 'alertdemo-backend') {
          //setBackendPluginId(res.data[i].uid);
          console.log('set backend plugin id to 1: ', res.data[i].id);
          setBackendPluginId(res.data[i].id);
        }
      }
    });
  }, []);

  useEffect(() => {
    console.log('here3');
    if (selected === 'phone') {
      setInputIcon('book');
      setinputPlaceholdText('Enter phone number');
    }
    if (selected === 'email') {
      setInputIcon('envelope');
      setinputPlaceholdText('Enter email address');
    }
  }, [selected]);

  //https://editor.swagger.io/?url=https://raw.githubusercontent.com/grafana/grafana/main/pkg/services/ngalert/api/tooling/api.json

  // axios.post('/api/v1/provisioning/contact-points',{
  //   name: 'test2',
  //   type: 'email',
  //   settings: {addresses:'rujoesmith@gmail.com'},
  //   uid: 'test'
  // })

  // axios.delete('/api/v1/provisioning/contact-points/test',{
  // })

  // axios.post('/api/v1/provisioning/alert-rules')

  const buttonClicked = async () => {
    const req = axios.post('/api/datasources/' + backendPluginId + '/resources/newAlert', {
      alertName: alertName,
      threshold: thresholdVoltage,
      contact: contactInfo,
      deleteTime: options.deleteTime,
    });
    //req instanceof Promise;
    const res = await req;
    console.log('res status: ', res.status);
    if (res.status === 200) {
      swal('Success!', 'Alert created!', 'success');
    } else {
      swal('Error!', 'Alert not created!', 'error');
    }

    // var contactPointCreated: boolean;
    // var alertRuleCreated: boolean;

    // const test = async () => {
    //   contactPointCreated = await createContactPoint();
    //   alertRuleCreated = await createAlertRule();
    // };

    // await test().then(() => {
    //   console.log('contact point created', contactPointCreated);
    //   console.log('alert rule created', alertRuleCreated);
    //   if (contactPointCreated === true && alertRuleCreated === true) {
    //     swal('Success!', 'Alert created!', 'success');
    //   } else {
    //     swal('Error!', 'Alert not created!', 'error');
    //   }
    // });
  };

  const deleteAllAlerts = async () => {
    const req = axios.get('/api/ruler/grafana/api/v1/rules');
    //req instanceof Promise;
    const res = await req;
    console.log(res.data);
    for (var i = 0; i < res.data.DemoAlerts[0].rules.length; i++) {
      console.log(res.data.DemoAlerts[0].rules[i].grafana_alert.uid);
      const req2 = axios.delete(
        '/api/v1/provisioning/alert-rules/' + res.data.DemoAlerts[0].rules[i].grafana_alert.uid
      );
      const res2 = await req2;
      console.log(res2.status);
    }
  };

  const deleteAllContactPoints = async () => {};

  // if (loggedIn === true) {
    if (options.showSeriesCount === true) {
      return (
        <div>
          <InlineField label="Max Voltage Threshold (V)" grow>
            <Slider
              value={7}
              min={7}
              max={24}
              step={0.5}
              onChange={(e) => {
                setThresholdVoltage(e);
              }}
            />
          </InlineField>
          <br></br>

          <RadioButtonGroup
            options={[
              { label: 'Phone', value: 'phone' },
              { label: 'Email', value: 'email' },
            ]}
            value={selected}
            onChange={(e) => {
              setSelected(e!);
              console.log(e);
            }}
            size={'md'}
            fullWidth={true}
          />
          <br></br>
          <Input
            width={width / 8}
            prefix={<Icon name={inputIcon} />}
            type={'text'}
            placeholder={inputPlaceholdText}
            onChange={(e) => {
              setContactInfo(e.currentTarget.value);
            }}
          />
          <br></br>
          <Input
            width={width / 8}
            prefix={<Icon name={'book-open'} />}
            type={'text'}
            placeholder={'Enter alert name'}
            onChange={(e) => {
              setAlertName(e.currentTarget.value);
            }}
          />
          <br></br>
          <Button
            //center button
            style={{ textAlign: 'center', width: '100%' }}
            type="submit"
            size="lg"
            variant={'primary'}
            fullWidth={true}
            onClick={buttonClicked}
          >
            Create New Alert
          </Button>
          <br></br>
          <br></br>
          <Button
            //center button
            style={{ textAlign: 'center', width: '100%' }}
            type="submit"
            size="lg"
            variant={'destructive'}
            fullWidth={true}
            onClick={deleteAllAlerts}
          >
            Delete All Alerts
          </Button>
          <br></br>
          <br></br>
          <Button
            //center button
            style={{ textAlign: 'center', width: '100%' }}
            type="submit"
            size="lg"
            variant={'destructive'}
            fullWidth={true}
            onClick={deleteAllContactPoints}
          >
            Delete All Contact Points
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <InlineField label="Max Voltage Threshold (V)" grow>
            <Slider
              value={5}
              min={options.minVoltageRange}
              max={options.maxVoltageRange}
              step={0.5}
              onChange={(e) => {
                setThresholdVoltage(e);
              }}
            />
          </InlineField>
          <br></br>
          <RadioButtonGroup
            options={[
              { label: 'Phone', value: 'phone' },
              { label: 'Email', value: 'email' },
            ]}
            value={selected}
            onChange={(e) => {
              setSelected(e!);
              console.log(e);
            }}
            size={'md'}
            fullWidth={true}
          />
          <br></br>
          <Input
            width={width / 8}
            prefix={<Icon name={inputIcon} />}
            type={'text'}
            placeholder={inputPlaceholdText}
            onChange={(e) => {
              setContactInfo(e.currentTarget.value);
            }}
          />
          <br></br>
          <Input
            width={width / 8}
            prefix={<Icon name={'book-open'} />}
            type={'text'}
            placeholder={'Enter alert name'}
            onChange={(e) => {
              setAlertName(e.currentTarget.value);
            }}
          />
          <br></br>
          <Button
            //center button
            style={{ textAlign: 'center', width: '100%' }}
            type="submit"
            size="lg"
            variant={'primary'}
            fullWidth={true}
            onClick={buttonClicked}
          >
            Create New Alert
          </Button>
        </div>
      );
    }
  // } else {
  //   return (
  //     <div style={{ textAlign: 'center', lineHeight: height.toString() + 'px' }}>
  //       {/* insert link */}
  //       You must be{' '}
  //       <a href="/login" style={{ color: 'blue' }}>
  //         logged in
  //       </a>{' '}
  //       to create an alert
  //     </div>
  //   );
  // }
};
